.messages-box, .messages-sidebar {
    height: 85vh !important;
}

.wal-form-date .calendar {
  position: relative;
  display: flex;
  padding: 40px 32px 32px 40px;
}

.rdrMonthName {
  display: none !important;
}
